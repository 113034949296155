import React from "react";

import "./styles.scss";

import MainInfo from "../../components/MainInfo/MainInfo";
import MainMenu from "../../components/MainMenu/MainMenu";
import MainAbout from "../../components/MainAbout/MainAbout";
import MainServices from "../../components/MainServices/MainServices";
import MainStory from "../../components/MainStory/MainStory";
import MainArchetypes from "../../components/MainArchetypes/MainArchetypes";
import MainBlog from "../../components/MainBlog/MainBlog";
import GoToPage from "../../components/GoToPage/GoToPage";

const Home = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <GoToPage></GoToPage>
      <MainInfo />
      <MainMenu />
      <MainAbout />
      <MainServices />
      <MainStory />
      <MainArchetypes />
      <MainBlog />
    </>
  );
};
export default Home;
