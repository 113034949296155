import React from "react";

import "./styles.scss";

import { Link, useParams, useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";

function ArchetypesArchetypes() {
  const { id } = useParams();
  const navigate = useNavigate();

  const archetypesContent = {
    I: {
      title: "I L’Archetipo Il Mago",
      content: String.raw`IL MAGO`,
      image: "/images/archetypes/I.webp",
    },
    II: {
      title: "архетип 2",
      content: "контент 2",
      image: "/images/archetypes/II.webp",
    },
    III: {
      title: "архетип 3",
      content: "контент 3",
      image: "/images/archetypes/III.webp",
    },
    IV: {
      title: "архетип 4",
      content: "контент 4",
      image: "/images/archetypes/IV.webp",
    },
    V: {
      title: "архетип 5",
      content: "контент 5",
      image: "/images/archetypes/V.webp",
    },
    VI: {
      title: "архетип 6",
      content: "контент 6",
      image: "/images/archetypes/VI.webp",
    },
    VII: {
      title: "архетип 7",
      content: "контент 7",
      image: "/images/archetypes/VII.webp",
    },
    VIII: {
      title: "архетип 8",
      content: "контент 8",
      image: "/images/archetypes/VIII.webp",
    },
    IX: {
      title: "архетип 9",
      content: "контент 9",
      image: "/images/archetypes/IX.webp",
    },
    X: {
      title: "архетип 10",
      content: "контент 10",
      image: "/images/archetypes/X.webp",
    },
    XI: {
      title: "архетип 11",
      content: "контент 11",
      image: "/images/archetypes/XI.webp",
    },
    XII: {
      title: "архетип 12",
      content: "контент 12",
      image: "/images/archetypes/XII.webp",
    },
    XIII: {
      title: "архетип 13",
      content: "контент 13",
      image: "/images/archetypes/XIII.webp",
    },
    XIV: {
      title: "архетип 14",
      content: "контент 14",
      image: "/images/archetypes/XIV.webp",
    },
    XV: {
      title: "архетип 15",
      content: "контент 15",
      image: "/images/archetypes/XV.webp",
    },
    XVI: {
      title: "архетип 16",
      content: "контент 16",
      image: "/images/archetypes/XVI.webp",
    },
    XVII: {
      title: "архетип 17",
      content: "контент 17",
      image: "/images/archetypes/XVII.webp",
    },
    XVIII: {
      title: "архетип 18",
      content: "контент 18",
      image: "/images/archetypes/XVIII.webp",
    },
    XIX: {
      title: "архетип 19",
      content: "контент 19",
      image: "/images/archetypes/XIX.webp",
    },
    XX: {
      title: "архетип 20",
      content: "контент 20",
      image: "/images/archetypes/XX.webp",
    },
    XXI: {
      title: "архетип 21",
      content: "контент 21",
      image: "/images/archetypes/XXI.webp",
    },
    XXII: {
      title: "архетип 22",
      content: "контент 22",
      image: "/images/archetypes/XXII.webp",
    },
  };
  const currentArchetype = archetypesContent[id];

  if (!currentArchetype) {
    return <div>Архетип не найден</div>;
  }
  return (
    <section className="archetype">
      <div className="container">
        <div className="archetype__wrapper">
          <button
            className="archetype__back-button back-arrow"
            onClick={() => navigate(-1)}
          >
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21 0H0V1H19.29L0 20.29V21H0.71L1 20.71L1.71 20L20 1.71V21H21V0Z"
                fill="currentColor"
              />
            </svg>
            <span className="underline">Torna indietro</span>
          </button>
          <h1 className="archetype__title title-1">{currentArchetype.title}</h1>
          <div className="archetype__text-box">
            <p className="archetype__text">{currentArchetype.content}</p>
            <p className="archetype__text">
              Jester è uno stato di libertà assoluta e un momento di impulso
              creativo. Molti considerano Jester stupido, ma questa "stupidità"
              dimostra che Jester non è vincolato da limitazioni e pregiudizi
              inventati. Il significato dell'energia assoluta dell'archetipo è
              che Jester vede ciò che gli altri non vedono, perché agli altri è
              stato detto "questo non può essere", e lui è stato dimenticato,
              non gli è stata cucita addosso tale funzione. L'impossibile non
              esiste per il buffone.
              <p className="archetype__subtext">
                Il lato negativo di Jester è la tentazione di non crescere mai,
                di rimanere un bambino a cui tutti devono tutto.
              </p>
            </p>
          </div>
          <div className="archetype__content-box">
            <div className="archetype__image-box">
              <span className="archetype__image-span">Descrizione breve:</span>
              <img
                className="archetype__image"
                src={currentArchetype.image}
                alt="photo"
              />
            </div>
            <div className="archetype__info-box">
              <h2 className="archetype__info-title underline">
                IL LATO POSITIVO È CHE:
              </h2>
              <ul className="archetype__positive-list">
                <li className="archetype__positive-item">
                  Scherzi e aspetti positivi
                </li>
                <li className="archetype__positive-item">Scherzi e aspetti </li>
                <li className="archetype__positive-item">
                  Scherzi e aspetti positivi
                </li>
              </ul>
              <h2 className="archetype__info-title underline">
                IN SVANTAGGIO:
              </h2>
              <ul className="archetype__negative-list">
                <li className="archetype__negative-item">
                  Scherzi e aspetti positivi
                </li>
                <li className="archetype__negative-item">Scherzi e aspetti </li>
                <li className="archetype__negative-item">
                  Scherzi e aspetti positivi
                </li>
              </ul>
              <p className="archetype__info-text">
                Per una persona con questo archetipo è importante essere attenti
                ai dettagli, sviluppare la capacità di parlare, esprimere i
                pensieri con competenza.
              </p>
              <div className="archetype__button-box">
                <Button text="CONSULENZA" className="archetype__info-btn " />
                <Button
                  text="ACQUISTA LA DESCRIZIONE COMPLETA DEGLI ARCHETIPI"
                  className="archetype__info-btn button--black"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ArchetypesArchetypes;
