// import React from "react";

// import "./styles.scss";

// import Link from "../../components/Link/Link";
// function MainInfo() {
//   return (
//     <section className="info">
//       <div className="container">
//         <div className="info__wrapper">
//           <div className="canvas"></div>
//           <h1 className="info__title title-1">
//             CALCOLA LA TUA MAPPA ARCHETIPICA E SCOPRI I TUOI ARCHETIPI
//           </h1>
//           <p className="info__text">
//             La mappa archetipica natale è una guida accurata alla nostra
//             personalità, che illustra le caratteristiche psicologiche personali,
//             i modelli di comportamento inerenti alla psiche, le lezioni di
//             maturità psicologica e le prove di espressione sociale, i talenti
//             innati, tutto ciò che porta alla realizzazione della missione scelta
//             dall'anima prima di venire in questo mondo. E molto altro ancora...
//           </p>
//           <Link
//             text="CALCOLARE"
//             className="info__link link--black"
//             path="calculator"
//           />
//         </div>
//       </div>
//     </section>
//   );
// }

// export default MainInfo;
import React, { useEffect } from "react";
import $ from "jquery";
import "jquery.ripples";
import "./styles.scss";
import Link from "../../components/Link/Link";

function MainInfo() {
  useEffect(() => {
    // Проверка поддержки WebGL
    const isWebGLSupported = () => {
      const canvas = document.createElement("canvas");
      return !!(
        window.WebGLRenderingContext &&
        (canvas.getContext("webgl") || canvas.getContext("experimental-webgl"))
      );
    };

    // Убедитесь, что DOM элемент доступен
    const rippleElement = $("#ripple");
    if (rippleElement.length && isWebGLSupported()) {
      try {
        rippleElement.ripples({
          resolution: 256,
          dropRadius: 20,
          perturbance: 0.04,
        });
      } catch (e) {
        console.error("Ripples effect initialization failed:", e);
      }
    }

    // Очистка эффекта при размонтировании компонента
    return () => {
      if (rippleElement.length && isWebGLSupported()) {
        rippleElement.ripples("destroy");
      }
    };
  }, []); // Пустой массив зависимостей, чтобы эффект выполнялся только один раз при загрузке компонента
  return (
    <section id="ripple" className="info">
      <div className="container">
        <div className="info__wrapper">
          <h1 className="info__title title-1">
            CALCOLA LA TUA MAPPA ARCHETIPICA E SCOPRI I TUOI ARCHETIPI
          </h1>
          <p className="info__text">
            “La Mappa Archetipica natale è una guida accurata alla tua
            personalità, che illustra in modo completo le tue aspirazioni e le
            cause dei tuoi problemi, ti permette di scoprire letteralmente
            tutto: su te stesso, sulla tua vita, sul tuo ambiente, e non solo
            scoprirlo, ma trovare ed iniziare il movimento lungo il percorso dei
            cambiamenti positivi. Sarà l'inizio del tuo Cammino verso te stesso
            e tua vera vita...”
          </p>
          <Link
            text="CALCOLARE"
            className="info__link link--black"
            path="calculator"
          />
        </div>
      </div>
    </section>
  );
}

export default MainInfo;
