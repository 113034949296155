import React from "react";

import "./styles.scss";

import { Link, useLocation } from "react-router-dom";

import gsap from "gsap";
import BurgerMenuList from "../BurgerMenuList/BurgerMenuList";

function BurgerMenu({ tl }) {
  const location = useLocation();

  React.useEffect(() => {
    tl.current = gsap.timeline({ paused: true });

    tl.current.to(".burger-menu", {
      duration: 0.5,
      y: "0",
    });

    tl.current.to(".burger-menu__nav", {
      duration: 1,
      x: "0",
    });

    tl.current.from(
      ".burger-menu .menu-list__list",
      {
        duration: 1,
        x: "-100%",
        opacity: 0,
      },
      "-=0.5"
    );
    tl.current.from(
      ".burger-menu .menu-list__link-wrapper",
      {
        duration: 1,
        stagger: {
          amount: 0.5,
        },
        x: "-100%",
        opacity: 0,
      },
      "-=1"
    );
    tl.current
      .from(".burger-menu .menu-list__link-span", {
        duration: 0.5,
        stagger: {
          amount: 0.5,
        },
        y: 100,
      })
      .from(
        ".burger-menu .menu-list__link-svg",
        {
          duration: 0.5,
          stagger: {
            amount: 0.5,
          },
          y: 100,
        },
        "-=1"
      )
      .from(
        [".burger-menu__logo", ".burger-menu__close"],

        {
          duration: 1,
          opacity: 0,
        },
        "-=1"
      );
  }, [location.pathname]);

  function menuClose() {
    if (tl.current) {
      tl.current.reverse();

      setTimeout(() => {
        document.body.classList.remove("menu-open");
      }, 2500);
    }
  }

  return (
    <div className="burger-menu">
      <nav className="burger-menu__nav">
        <div className="container">
          <div className="burger-menu__top">
            <Link
              className="burger-menu__logo"
              to="/"
              onClick={() => menuClose()}
            >
              <img src="./images/icons/logo-black.svg" alt="logo" />
            </Link>

            <div className="burger-menu__close">
              <button className="close-button" onClick={() => menuClose()}>
                <div className="in">
                  <div className="close-button-block"></div>
                  <div className="close-button-block"></div>
                </div>
                <div className="out">
                  <div className="close-button-block"></div>
                  <div className="close-button-block"></div>
                </div>
              </button>
            </div>
          </div>
          <div className="burger-menu__content">
            <BurgerMenuList menuClose={menuClose}></BurgerMenuList>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default BurgerMenu;
