import { configureStore } from "@reduxjs/toolkit";
import resultsReducer from "./slices/resultsSlice";
import contactFormReducer from "./slices/contactFormSlice";
import buyFormReducer from "./slices/buyFormSlice";
import privacyReducer from "./slices/privacySlice";

export const store = configureStore({
  reducer: {
    results: resultsReducer,
    contactForm: contactFormReducer,
    buyForm: buyFormReducer,
    privacy: privacyReducer,
  },
});
